@import "../../utils/_variables";

.payment-method {
  .AnimationWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .DisplayName__input {
      padding: 15px;
      margin-bottom: 15px;
    }
    .SupportMessage__title {
      margin-bottom: 15px;
    }
    .SupportMessage__textarea {
      margin-bottom: 30px;
    }
    .PaymentMethod__title {
      margin-bottom: 15px;
    }
    .PaymentMethod__apple-pay {
      width: 100%;
      margin-bottom: 10px;
    }
    .PaymentMethod__google-pay {
      width: 100%;
      margin-bottom: 0px;
    }
    .PaymentMethod__divider {
      margin-bottom: 20px;
    }
    .PaymentMethod__credit-card {
      width: 100%;
      margin-bottom: 10px;
    }

    .GAPButtonWrapper {
      width: 100%;
      .not-allowed-helper {
        text-align: center;
        .GAPHelper__title {
          margin: 10px 0;
        }
        .GAPHelper__CTC {
          .GAPHelper__CTC--button {
            width: 100%;
            @media #{$lg} {
              width: 80%;
            }
            font-size: 14px;
            background: #fff;
            border: 1px solid #ccc;
            height: 35px;
            text-align: left;
            padding: 0 40px 0 10px;
            margin-bottom: 30px;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            outline: none;
            &::before {
              content: "Copied!";
              position: absolute;
              background: rgba(105, 53, 255, 0.9);
              text-align: center;
              color: #fff;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              visibility: hidden;
              opacity: 0;
              transition: all 400ms linear;
              z-index: 99;
            }
            &.copied::before {
              visibility: visible;
              opacity: 1;
            }
            .GAPHelper__CTC--text {
              cursor: pointer;
            }
            .GAPHelper__CTC--icon {
              font-size: 18px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
              cursor: pointer;
            }
          }
        }
        .PaymentMethod__title {
          margin-bottom: 15px;
        }
        .PaymentMethod__details {
          margin-bottom: 15px;
        }
        .GPayHelper {
        }
        .APayHelper {
        }
      }
    }
  }
}
