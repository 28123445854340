@import '../../utils/_variables';

// Important to keep this isotaled:
.Notifications__title {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: $primary-color;
}
// Important keep this isotaled:
.Notifications__content {
  min-width: 300px;
  max-height: 50vh;
  height: 120px;
}

.AccountMenuPopover{
  .AccountMenu__title{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: $primary-color;
    font-size: 13px;
  }
  .ant-popover-inner-content{
    padding: 0;
    .AccountMenu__content{
      min-width: 170px;
      max-height: 50vh;
      // height: 125px;
      .navigation {
        .navigation__list{
          list-style: none;
          padding: 0;
          margin: 0;
          li{
            a {
              display: flex;
              position: relative;
              justify-content: flex-start;
              align-items: center;
              font-size: 13px;
              padding: 10px 20px;
              color: rgba(123, 136, 168, 0.7);
              font-weight: 300;
              min-height: 32px;
              border-bottom: 1px solid #f0f0f0;
              &:hover{
                font-weight: 500;
                color: $primary-color;
                background: rgba(123, 136, 168, 0.02);
              }
              .nav-item-icon{
                margin-right: 12px;
                svg{
                  width: 16px;
                  height: 16px;
                }
              }
            }
            &.li-logout{
              margin-top: auto;
              a{
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}


.toggle-mobile-menu{
  background-color: transparent;
  border: none;
  width: 50px;
  height: 50px;
  outline: none;
  cursor: pointer;
  @media #{$md}{
    display: none;
  }
  .MyAvatar__image{
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    box-shadow: 0px 0px 5px #CCC;
  }
}

.Main__Header{
  .Main__Header__wrapper{
    position: relative;
    .LeftHeader {
      left: 0;
      position: absolute;
      top: 50px;
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 50px;
      &.hideOnMedium{
        @media #{$md} {
          display: none;
        }
      }
      .li-signin, .li-signup {
        display: none;
        @media #{$md} {
          display: block;
        }
      }
      .navigation {
        ul {
          list-style: none;
          padding: 0;
          display: flex;
          li{
            margin-left: 25px;
          }
        }
      }
    }
    .RightHeader{
      position: absolute;
      top: 50px;
      right: 0px;
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      @media #{$md}{
        right: 10px;
        flex-direction: row;
      }
      .Notifications{
        height: 50px;
        padding: 0 10px 0 10px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @media #{$md}{
          width: initial;
          padding: initial;
          margin-left: initial;
          margin-right: initial;
        }
        .BellIcon{
          cursor: pointer;
          svg{
            width: 22px;
          }
        }
      }
      .RightHeader__divider{
        display: none;
        height: 25px;
        margin: 0 12px 0 15px;
        @media #{$md}{
          display: inline-block;
        }
      }
      .MyAvatar{
        display: none;
        position: relative;
        @media #{$md} {
          display: block;
        }
        .MyAvatar__main {
          display: flex;
          justify-content: center;
          align-items: center;
          // padding-left: 20px;
          & > span{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .MyAvatar__badge{
            // margin-right: 15px;
            // height: 30px;
            .MyAvatar__image{
              width: 40px;
              height: 40px;
              background-position: center;
              background-size: cover;
              border-radius: 100%;
              box-shadow: 0px 0px 5px #CCC;
              // margin-left: 20px;
            }
          }
          .MyAvatar__details{
            display: none;
            position: relative;
            flex-direction: column;
            padding-right: 14px;
            text-align: right;
            @media #{$md}{
              display: flex;
            }
            .MyAvatar__details--name {
              font-weight: 500;
              font-size: 15px;
              margin: 0;
              line-height: 15px;
              color: $primary-color;
            }
            .MyAvatar__details--band-name {
              font-size: 13px;
              font-weight: 400;
              color: $copyBase;
              margin: 0;
            }
          }
        }
        .MyAvatar__menu{
          position: absolute;
          width: calc(100% + 20px);
          border-radius: 5px;
          top: -10px;
          right: -10px;
          padding-top: 55px;
          transition: 0.3s linear all;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          background: #FFF;
          border: 1px solid rgba(105, 53, 255, 0.1);
          padding:  55px 10px 10px;
          opacity: 0;
          visibility: hidden;
        }
        &:hover .MyAvatar__menu{
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.site-wide-notification{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 20px 0 20px;

  .ant-alert-icon{
    display: none;
  }
  &>.ant-alert-content{
    .ant-alert-message{
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  &>.ant-alert-action{
    margin-left: 0px;
    margin-top: 15px;
  }

  @media #{$md}{
    width: 100%;
    margin: 20px 40px 0 40px;
    flex-direction: row;
    .ant-alert-icon{
      display: inline-block;
    }
    &>.ant-alert-action{
      margin-left: 8px;
      margin-top: 0px;
    }
    &>.ant-alert-content{
      .ant-alert-message{
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  }
}
