$primary-color: #6935ff;
$copyDarker: #2d3958;
$copyBase: #7b88a8;
$gray-color: #f8faff;
$white-color: #ffffff;

$xsSize: 576px;
$smSize: 576px;
$mdSize: 768px;
$lgSize: 992px;
$xlSize: 1200px;
$xxlSize: 1600px;

$xs: '(max-width: #{$xsSize})';
$sm: '(min-width: #{$smSize})';
$md: '(min-width: #{$mdSize})';
$lg: '(min-width: #{$lgSize})';
$xl: '(min-width: #{$xlSize})';
$xxl: '(min-width: #{$xxlSize})';

$facebook-color: #1877f2;
$soundcloud-color: #ff6200;
$twitter-color: #08a0e9;
$bandcamp-color: #61939d;
$instragram-color: #c13584;
$spotify-color: #2ebd59;
$youtube-color: #ef5656;
