.cc-form {
  .AnimationWrapper {
    .CreditCardInfo__title {
      font-size: 17px;
      text-align: center;
      margin: 0px 10px 20px;
    }
  }

  .CreditCardWrapper .ant-form-item-control-input {
    background: #fff;
    padding: 15px 15px;
    border: solid 1px rgb(218, 218, 218);
    border-radius: 2px;
  }
}
