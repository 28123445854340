@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import './utils/_variables';

// $btn-custom-primary: #6935ff; // Now this is overriden on craco.config.js
$btn-custom-submit-height: 55px;

body{
  font-family: 'DM Sans', sans-serif;
}

#root{
  padding-bottom: 0px;
  position: relative;
  min-height: 100vh;
  @media #{$md}{
    padding-bottom: 50px;
  }
}

.ant-avatar > img{
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  transform: translateY(-25%);
}

.MuseItPopover .ant-popover-inner{
  border: 1px solid rgba(105, 53, 255, 0.1);
  box-shadow: 0px 1px 2px rgba(105, 53, 255, 0.02);
  // box-shadow: 0 3px 6px -4px rgba(105, 53, 255, 0.12), 0 6px 16px 0 rgba(105, 53, 255, 0.08), 0 9px 28px 8px rgba(105, 53, 255, 0.05);
}

.MuseItPopover > .ant-popover-content > .ant-popover-arrow {
  top: 8px;
  border-top-color: #fff;
  border-right-color: white;
  border-bottom-color: white;
  border-left-color: #fff;
  box-shadow: -1px -1px 1px 0px rgba(222, 212, 255, 0.7);
  z-index: 99;
}

.ant-form-item{
  margin-bottom: 20px;
}

.ant-form-item-with-help{
  margin-bottom: 10px;
}

.ant-input-lg {
  padding: 14px 14px;
  font-size: 15px;
}

.ant-input-affix-wrapper-lg {
  padding: 14px 14px;
  font-size: 15px;
}

textarea.ant-input{
  padding: 14px 14px;
  font-size: 15px;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 24px;
}

.ant-form-item-has-feedback .ant-form-item-children-icon{
  right: 6px;
}

.btn-custom-submit{
  height: $btn-custom-submit-height;
  display: block;
  margin: 10px auto 30px;
  font-weight: 500;
  padding: 14px 30px;
}

@keyframes highlight {
  0%   { background-color: rgba(255, 251, 216, 0); }
  25%  { background-color: rgba(255, 251, 216, 1); }
  30%   { background-color: rgba(255, 251, 216, 0); }
  35%  { background-color: rgba(255, 251, 216, 1); }
  100%  { background-color: rgba(255, 251, 216, 0); }
}

.highlight{
  background-color: rgba(255, 251, 216, 0);
  animation-name: highlight;
  animation-duration: 4s;
  animation-timing-function: ease;
}
