@import '../../../utils/_variables';

.connectMsg {
  color: copyBase;
}

.copy > li {
  margin-bottom: 10px;
  color: $copyBase;
}
