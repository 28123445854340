@import '../../../utils/_variables';

.MyMoney {
  overflow: hidden;
  .MyMoney__numbers {
    margin-bottom: 50px;
    display: flex;
    .EarningsCard {
      width: 80%;
      min-width: 250px;
      @media #{$lg} {
        width: 32%;
      }
      padding: 30px 30px;
      border-radius: 14px;
      .EarningsCard__title {
        color: #fff;
        // @media #{lg}{
        //   font-size: 14px;
        // }
        // @media #{$xl}{
        font-size: 14px;
        // }
        letter-spacing: 1px;
        font-weight: 600;
        text-align: center;
      }
      .EarningsCard__amount {
        color: #fff;
        // @media #{lg}{
        //   font-size: 26px;
        // }
        // @media #{$xl}{
        font-size: 36px;
        // }
        letter-spacing: 1px;
        font-weight: 500;
        text-align: center;
      }
      &.EarningsCard__current {
        background: #f68058;
      }
      &.EarningsCard__paid {
        background: #f8ac25;
      }
      &.EarningsCard__total {
        background: #8256ff;
      }
    }
  }
  &__tabs {
    .payments-note {
      margin-top: 10px;
      // padding: 0px 20px;
      text-align: left;
      &.last {
        margin-bottom: 60px;
      }
      .anticon {
        margin-right: 5px;
        color: $primary-color;
      }
    }
    .ant-tabs-nav {
      @media #{$lg} {
        width: 100%;
      }
      .ant-tabs-tab {
        padding: 20px 0;
        margin: 0 40px 0 0;
        @media #{$lg} {
          text-align: center;
          padding: 20px 10px;
          margin: 0 8% 0 8%;
          font-size: 18px;
          &:first-child {
            // margin-left: 50px;
          }
        }
      }
      .ant-tabs-ink-bar {
        height: 4px;
      }
    }
  }
}
