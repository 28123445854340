@import '../../utils/_variables';
.SignIn{
  input[type=email]{
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  min-height: 100vh;
  .SignIn__title{
    font-weight: 600;
    font-size: 45px;
    margin-bottom: 0;
    text-align: center;
    color: $copyDarker;
    @media #{$md}{
      font-size: 56px;
    }
    @media #{$lg}{
      text-align: left;
      font-size: 72px;
    }
  }

  .SignIn__details{
    font-size: 17px;
    text-align: center;
    color: $copyBase;
    @media #{$lg}{
      text-align: left;
      font-size: 20px;
    }
  }

  .signup-link{
    text-align: center;
    @media #{$lg}{
      text-align: center;
    }
  }


  .btn-custom-submit{
    @media #{$lg}{
      margin-left: 0;
    }
  }

  .forgot-your-password{
    float: right;
  }


  .SignIn__art-wrapper{
    @media #{$lg}{
      // margin-top: 40px;
      margin-bottom: auto;
      margin-top: auto;
    }
  }
}
