@import '../../../utils/_variables';

.show-on-lg {
  display: none;
}

@media #{$lg} {
  .show-on-lg {
    display: block;
  }

  .hide-on-lg {
    display: none;
  }
}

.changePassword {
  margin-bottom: 0;
}

.BandNameField,
.BiographyField {
  margin-top: 20px;
}

.SlugField {
  .ant-input-affix-wrapper-lg {
    padding: 17px 14px;
  }

  .ant-input-prefix {
    margin-right: 0px !important;
  }
}

.SpecialFieldBlock {
  padding-top: 30px;

  .SpecialFieldBlock__title {
    color: $copyDarker;
    margin-bottom: 0px;
    display: flex;
    align-items: center;

    span.ant-tag {
      margin-left: 10px;
    }
  }

  .SpecialFieldBlock__details {
    color: $copyBase;
    margin-bottom: 5px;
  }

  .MuseitURLPrefix {
    font-weight: 500;
    // color: $copyBase;
  }

  .ConnectYourBankAccount {
    margin: 10px auto 10px;
    height: 55px;
    padding: 16px 30px;
    font-weight: 500;
    line-height: normal;
  }

  .ant-form-item {
    margin-top: 15px;
  }

  .poweredByStripe {
    margin: 10px auto 40px;
  }

  .merchTitle {
    margin-bottom: 10px;
  }
}

.ProfileFormSubmit {
  @media #{$md} {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
}

.ant-picker-large {
  padding: 6.5px 11px 6.5px;
  width: 100%;
  min-height: 53px !important;
}

.ProfileForm__SocialLinks--Group {
  display: flex;

  .ant-form-item {
    width: 80%;
  }

  .anticon-minus-circle {
    position: relative;
    top: 15px;
  }
}

.profile-form {
  #react-qrcode-logo {
    cursor: pointer;
  }

  .QRContainer {
    position: relative;
    display: inline-flex;
    cursor: pointer;

    .download-layer {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      transition: 300ms ease all;

      .anticon {
        font-size: 52px;
      }
    }

    &:hover .download-layer {
      visibility: visible;
      opacity: 1;
    }
  }
}
