@import '../../utils/_variables';

.ArtBubbles{
  position: relative;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -100px;
  width: 100%;
  transform: scale(0.6);
  @media #{$md}{
    height: 670px;
  }
  @media #{$lg}{
    transform: scale(1);
    margin-top: 0px;
    margin-bottom: auto;
  }
  .customer-circle{
    display: flex;
    position: absolute;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 1px 1px 42px 44px rgba(188, 196, 218, 0.12);
    &-01{
      left: auto;
      top: auto;
      right: 50%;
      bottom: 27%;
      width: 150px;
      height: 150px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-02.png");
    }
    &-02{
      left: 8%;
      top: 11%;
      right: auto;
      bottom: auto;
      width: 110px;
      height: 110px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-03.png");
    }
    &-03{
      left: auto;
      top: 0%;
      right: 16%;
      bottom: auto;
      width: 110px;
      height: 110px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-01.png");
    }
    &-04{
      left: auto;
      top: auto;
      right: 7%;
      bottom: 43%;
      width: 100px;
      height: 100px;
      padding: 25px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-04.png");
    }
    &-05{
      left: auto;
      top: auto;
      right: 29%;
      bottom: 1%;
      width: 120px;
      height: 120px;
      padding: 25px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-05.png");
    }
    &-06{
      left: 12%;
      top: auto;
      right: auto;
      bottom: 8%;
      width: 72px;
      height: 72px;
      padding: 20px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-01.png");
    }
    &-07{
      left: auto;
      top: 25%;
      right: auto;
      bottom: auto;
      width: 72px;
      height: 72px;
      padding: 20px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/customer-05.png");
    }
  }
  .bg-element {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: auto;
    z-index: -1;
    width: 85%;
    max-width: 850px;
    min-height: 480px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 32px;
    background-color: #f8faff;
    // transform: translateY(-50%);
    &.bg-social {
      max-width: 470px;
      min-height: 520px;
      background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/wave-bg.svg");
      background-position: 50% 50%;
      background-size: 230%;
    }
  }
}

.SignUp{
  .ArtBubbles{
    margin-top: -100px;
    @media #{$lg}{
      margin-top: 0px;
    }
  }
}
