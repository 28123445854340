strong {
    color: #6935ff;
    font-weight: bold;
}

u {
    text-decoration: underline;
    font-weight: bold;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px 10px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 48px;
}


#signup {
    .form-wrapper .hide {
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        height: 0;
    }

    .form-wrapper .hide {
        display: none;
    }

    .btn-subscribe {
        margin: 30px auto 20px auto;
        span {
            margin-right: 5px;
        }
    }

    .signup-steps {
        margin-top: 20px;
        margin-bottom: 20px;

        .interactive-step {
            cursor: pointer;
        }
    }
}