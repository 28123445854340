@import "../../utils/_variables";

.Musician {
  .Musician__cover {
    // left: 0;
    // top: 110px;
    // position: absolute;
    margin-top: 110px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      content: "";
      display: block;
      padding-top: 300px;
      @media #{$lg} {
        padding-top: 430px;
      }
    }
  }
  input[type="email"] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  // padding: 340px 3% 100px;
  @media #{$lg} {
    // padding-top: 455px;
  }
  background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/wave-top-left-corner.svg");
  background-position: 0% -300%;
  background-size: 1200px;
  background-repeat: no-repeat;
  .Musician__wrapper {
    .CurrentMusician {
      .Musician__avatar {
        margin-bottom: 15px;
        width: 140px;
        // height: 120px;
        border-radius: 100%;
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        @media #{$lg} {
          width: 16vw;
        }
        @media #{$xl} {
          width: 13vw;
        }
        @media #{$xxl} {
          width: 11vw;
        }
        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }
      }
      .Musician__name {
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 0px;
        text-align: center;
        color: $copyDarker;
        margin-top: 80px;
        @media #{$lg} {
          font-size: 40px;
          line-height: 40px;
          margin-top: 9.5vw;
        }
        @media #{$xl} {
          margin-top: 7.5vw;
        }
        @media #{$xxl} {
          margin-top: 6.5vw;
        }
      }
      .Musician__band-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $primary-color;
        @media #{$lg} {
          font-size: 20px;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
      .Musician__bio {
        font-size: 15px;
        text-align: center;
        margin-bottom: 25px;
        max-width: 390px;
        color: $copyBase;
        @media #{$lg} {
          font-size: 18px;
          max-width: 550px;
          line-height: 24px;
          margin-bottom: 25px;
        }
      }
      .Musician__sn {
        margin: 0px 0px 30px 0px;
        @media #{$lg} {
          margin: 0px 0px 30px 0px;
        }
        &-item {
          margin-left: 10px;
          &-link {
          }
        }
      }
    }
    .SupportForMusician {
      max-width: 350px;
      @media #{$lg} {
        max-width: 450px;
      }
      .SupportForMusician__wrapper {
        position: relative;
        // overflow: hidden;
        border-radius: 32px;
        transition: ease all 800ms;
        transition-delay: 1800ms;
        &.amount-selected-active {
          margin-top: 30px;
          z-index: 1;
        }
        &.not-allowed {
          .donations-not-allowed {
            margin: 40px 20px 80px 20px;
            text-align: center;
          }
        }
        .SupportForMusicianContainer {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          min-height: 400px;
          align-items: center;
          padding-top: 30px;

          .Step-1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 85%;
            @media #{$lg} {
              width: 70%;
            }
            .Step--title {
              color: $copyDarker;
              text-align: center;
              margin: 0px 20px 10px 20px;
              font-size: 17px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              @media #{$lg} {
                font-size: 22px;
                margin: 10px 20px 10px 20px;
              }
            }
            .Step--description {
              color: $copyBase;
              font-family: Arial;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 18px;
              letter-spacing: normal;
              text-align: center;
              margin: 0px 20px 20px;
              // max-width: 60%;
              @media #{$lg} {
                font-size: 15px;
                margin: 0px 0px 30px;
              }
            }
            .Step--buttons {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              text-align: center;
              justify-content: center;

              .amount-btn-wrapper {
                width: 45%;
                margin-left: 10px;
                margin-bottom: 10px;
                &:nth-child(odd) {
                  margin-left: 0px;
                }
              }

              .select-amount-btn,
              .select-amount-custom {
                width: 100%;
                position: relative;
                padding: 0;
                border-radius: 15px;
                box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.03);
                background-color: $white-color;
                border: none;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $primary-color;
                outline: none;
                cursor: pointer;
                height: inherit;
                @media #{$lg} {
                  font-size: 25px;
                }
                &-wrapper {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:after {
                    display: block;
                    padding-bottom: 100%;
                    content: "";
                  }
                }
              }
              .select-amount-custom {
                .custom_amount {
                }
                .transparent-number-field {
                  background: rgba(0, 0, 0, 0);
                  outline: none;
                  height: 100%;
                  width: 100%;
                  text-align: center;
                  border: none;
                  color: $primary-color;
                  font-weight: 600;
                  position: absolute;
                }
                .select-amount-custom-btn {
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translate(-50%, 50%);
                  visibility: hidden;
                  opacity: 0;
                  pointer-events: none;
                  // display: none;
                  transition: 300ms ease all;
                  background: #2e7d32;
                  border: 1px solid #43a047;
                  border-radius: 3px;
                  color: #fff;
                  font-size: 12px;
                  /* height: 40px; */
                  /* width: 40px; */
                  text-align: center;
                  text-transform: uppercase;
                  padding: 5px 10px;
                  letter-spacing: 2px;
                  font-weight: 600;
                  outline: none;
                  cursor: pointer;
                  &.show {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                    // display: block;
                  }
                }
              }
            }
          }
        }
      }
      .bg-layout {
        position: absolute;
        left: 0%;
        top: 0;
        right: 0%;
        bottom: auto;
        // z-index: -1;
        // width: 85%;
        // max-width: 850px;
        min-height: 85%;
        margin-right: auto;
        margin-left: auto;
        border-radius: 32px;
        background-color: $gray-color;
        background-image: url("https://museit.s3-us-west-1.amazonaws.com/resources/wave-bg.svg");
        background-position: 50% 50%;
        background-size: 230%;
        margin-bottom: 50px;
        transition: ease all 800ms;
        transition-delay: 1800ms;
        &.amount-selected-active {
          top: 35px;
        }

        &.not-allowed {
          border-radius: 12px;
        }

        &[current-step="2"] {
          min-height: 90%;
          &.no-GoogleApple-btn {
            min-height: 90%;
          }
        }
        &[current-step="3"] {
          min-height: 76%;
        }

        &[current-step="4"] {
          min-height: 76%;
        }
      }
    }
  }
}

.Step-2 {
  width: 85%;
}

.Step-3 {
  width: 85%;
  .AnimationWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .DisplayName__input {
      padding: 15px;
      margin-bottom: 15px;
    }
    .SupportMessage__title {
      margin-bottom: 15px;
    }
    .SupportMessage__textarea {
      margin-bottom: 30px;
    }
    .PaymentMethod__title {
      margin-bottom: 15px;
    }
    .PaymentMethod__apple-pay {
      width: 100%;
      margin-bottom: 10px;
    }
    .PaymentMethod__google-pay {
      width: 100%;
      margin-bottom: 0px;
    }
    .PaymentMethod__divider {
      margin-bottom: 20px;
    }
    .PaymentMethod__credit-card {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.Step-4 {
  width: 85%;
}

.GlobalReturn {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #f9be89;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 7px;
  border-radius: 32px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  font-weight: 600;
  transition: ease all 800ms;
  transition-delay: 1800ms;
  cursor: pointer;
  &.amount-selected-active {
    opacity: 1;
  }
  .arrow-left {
    position: absolute;
    left: 7.5%;
    top: 20px;
    transform: translateY(-50%);
    font-size: 14px;
  }
}

.SuccessModal {
  .ant-modal-content {
    // box-shadow: none;
    // background-color: rgba(0,0,0,0);
    // text-align: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // color: #FFF;
    // font-weight: 500;
    // font-size: 18px;
  }
}

.ApplePayBtn {
  width: 100%;
}

.GooglePayBtn {
  width: 100%;
  .gpay-button {
    width: 100% !important;
  }
}

.VenmoPayBtn {
  width: 100%;
  color: #fff;
  background-color: #3d95ce;
  border-color: #318ecc;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  @media screen and (min-width: 768px) {
    display: none;
  }
  &:hover {
    background-color: #60b8f1 !important;
    border-color: #39b0fd !important;
  }
}
