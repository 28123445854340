@import '../../../../utils/_variables';


.ant-table table {
  border-spacing: 0px 15px;
}

.ant-table-tbody>tr.ant-table-row-level-0>td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-left: solid 1px rgba(0, 0, 0, 0);
  transition: background 0.3s, border 0.3s;
}

.ant-table-tbody>tr.ant-table-row-level-0>td:last-child {
  border-left: solid 1px rgba(0, 0, 0, 0);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: background 0.3s, border 0.3s;
}

// Now that tr are clickable to expand:
.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row {
  // cursor: pointer;
  border-radius: 15px;
}

.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row:hover,
.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row.expanded {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row>td {
  background: #f8faff;
  border-top: solid 1px rgba(0, 0, 0, 0);
  border-bottom: solid 1px rgba(0, 0, 0, 0);
  transition: background 0.3s, border 0.3s;
}

.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row:hover>td,
.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row.expanded>td {
  background: #f8faff;
  border-top: 1px solid rgba(105, 53, 255, 0.1);
  border-bottom: 1px solid rgba(105, 53, 255, 0.1);
  transition: background 0.3s, border 0.3s;
}

.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row:hover>td:first-child,
.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row.expanded>td:first-child {
  border-left: 1px solid rgba(105, 53, 255, 0.1);
  transition: background 0.3s, border 0.3s;
}

.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row>td:last-child {
  border-right: 1px solid rgba(105, 53, 255, 0);
  transition: background 0.3s, border 0.3s;
}

.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row:hover>td:last-child,
.ant-table-tbody>tr.ant-table-row-level-0.ant-table-row.expanded>td:last-child {
  border-right: 1px solid rgba(105, 53, 255, 0.1);
  transition: background 0.3s, border 0.3s;
}


.ant-table-thead>tr>th {
  background: none;
}

.ant-table-thead th.ant-table-column-sort {
  background: none;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: none;
}


// Second Level:
.ant-table-expanded-row.ant-table-expanded-row-level-1>td {
  background: none;
  border-bottom: none;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1:hover>td {
  background: none;
  border-bottom: none;
}

.ExpandedDetails {
  margin-top: -31px;
  padding: 30px 30px;
  background: rgba(248, 250, 255, 0.3);
  border: 1px solid rgba(105, 53, 255, 0.1);
  border-top: none;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


.amount-value {
  text-transform: uppercase;
  margin-left: 4px;
}

.custom-row-avatar {
  width: 32px;
  height: 32px;
  background-size: cover;
  border-radius: 100%;
}


.extra-user-menu {
  font-size: 14px;
  // font-weight: 500;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    color: $primary-color;
    // font-weight: 500;
    background-color: rgba(105, 53, 255, 0.1);
  }
}