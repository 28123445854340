@import '../../utils/_variables';

.Recover{
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$md}{

  }
  @media #{$lg}{
    height: calc(100vh - 120px);
  }
  .Recover__wrapper{
    width: 350px;
    padding-top: 120px;
    max-width: 80%;

  }
}
