@import './_variables';

.FacebookIcon {
  color: #{$facebook-color};
  svg{
    width: 100%;
    fill: #{$facebook-color};
  }
}
.SoundCloudIcon {
  color: #{$soundcloud-color};
  svg{
    width: 100%;
    fill: #{$soundcloud-color};
  }
}
.TwitterIcon{
  color: #{$twitter-color};
  svg{
    width: 100%;
    fill: #{$twitter-color};
  }
}
.BandCampIcon{
  color: #{$bandcamp-color};
  svg{
    width: 100%;
    fill: #{$bandcamp-color};
  }
}
.InstagramIcon{
  color: #{$instragram-color};
  svg{
    width: 100%;
    fill: #{$instragram-color};
  }
}
.SpotifyIcon{
  color: #{$spotify-color};
  svg{
    width: 100%;
    fill: #{$spotify-color};
  }
}
.YoutubeIcon{
  color: #{$youtube-color};
  svg{
    width: 100%;
    fill: #{$youtube-color};
  }
}
.GlobeIcon{
  color: #{$copyBase};
  svg{
    width: 100%;
    fill: #{$copyBase};
  }
}
.LinkIcon{
  color: #{$copyDarker};
  svg{
    width: 100%;
    fill: #{$copyDarker};
  }
}
