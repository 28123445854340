@import '../../../utils/_variables';

.designer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.configurer {
  position: relative;
  flex-grow: 1;
  aspect-ratio: 1;
  max-width: 350px;
  overflow: hidden;
}

.shirt {
  position: absolute;
  height: 100%;
}

.side-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 200px;
}

.switch {
  width: 14px;
}

.designer-btns {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
