@import '../../../../utils/_variables';

/* 
Important!, to get the font unicodes, go to the next url:
https://fonts.googleapis.com/css?family=Montserrat */

/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.UsersDrawer {
    .AdvertisingGenerator {
        display: flex;
        padding-bottom: 50px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media #{$xl} {
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .PDFOverflowHider {
            position: relative;
            overflow: hidden;
        }

        .AdvertisingGenerator__details {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 50px;

            @media #{$xl} {
                width: 50%;
                display: flex;
                align-items: flex-start;
                margin-bottom: 0px;
            }

            .title {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 39px;
                margin-bottom: 0;
                max-width: 500px;
                // text-align: center;
            }

            .instructions {
                padding: 0;
                list-style: none;
                margin-top: 32px;
                max-width: 500px;

                li {
                    color: #505358;
                    margin-bottom: 15px;
                    font-family: 'DM Sans';
                    font-style: normal;
                    // font-weight: bold;
                    font-size: 18px;
                    line-height: 23px;

                    strong {
                        color: #505358;
                        font-weight: 500;
                    }
                }
            }
        }

        .AdvertisingGenerator__tool {

            display: flex;
            width: 360px;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            @media #{$xl} {
                width: 450px;
            }

            .tool-bar {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .preview-span {
                    font-size: 14px;
                    color: #777;
                }

                .save-changes {
                    margin-bottom: 10px;
                }
            }
        }

        .AdvertisingGenerator__tool,
        .HiddenElements {
            .MainPage {
                width: 900px;

                &.hidden {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .MainPage__wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .Headline {
                        font-size: 25px;
                        font-family: 'Montserrat';
                        letter-spacing: 0.12em;
                        font-weight: 600;
                        text-transform: uppercase;
                        line-height: calc(25px + 10px);
                        text-align: center;
                        margin-bottom: 30px;
                        outline: none;
                        border: none;
                        margin-right: 6px;
                        width: calc(100% - 12px);
                        overflow: hidden !important;
                    }

                    .MusicianName {
                        font-size: 55px;
                        font-family: 'Montserrat';
                        letter-spacing: 0.38em;
                        font-weight: bold;
                        text-transform: uppercase;
                        line-height: calc(50px + 22px);
                        text-align: center;
                        margin-bottom: 0;
                        max-width: 850px;
                        outline: none;
                        border: none;
                        margin-right: -0.38em;
                        overflow: hidden !important;
                    }

                    .ExportedImageQR {
                        border: 1px solid #000;
                        width: 442px;
                    }

                    .plain-musician-url {
                        font-family: 'Montserrat';
                        margin-top: 10px;
                        margin-left: 0.38em;
                        width: 700px;
                        text-align: center;

                        .band-url {
                            display: inline-block;
                            width: 100%;

                            &.block {
                                display: block;
                            }
                        }
                    }

                    .waves {
                        background-image: url(/static/media/waves.32f20be5.png);
                        width: 380px;
                        background-size: contain;
                        height: 110px;
                        background-repeat: no-repeat;
                        margin-bottom: 15px;
                        background-position: center;
                    }

                    .donation-methods {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 45px;
                        width: 420px;

                        .method-logo {
                            position: relative;
                            width: 27%;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;

                            &::before {
                                display: block;
                                content: "";
                                padding-top: 56.25%;
                            }
                        }
                    }

                    .PoweredBy {
                        font-size: 22px;
                        font-family: 'Montserrat';
                        letter-spacing: 0.12em;
                        font-weight: 600;
                        text-transform: uppercase;
                        line-height: calc(20px + 29.6px);
                        text-align: center;
                        margin-top: 60px;
                        margin-bottom: 30px;
                        outline: none;
                        border: none;
                        margin-right: -0.12em;
                        width: 100%;
                    }
                }
            }

            .Preview__wrapper {

                position: relative;
                width: 100%;
                height: 520px;
                padding: 40px 0;
                border: 1px solid #777;
                margin-bottom: 30px;
                padding-top: 40px;

                @media #{$xl} {
                    position: relative;
                    width: 450px;
                    height: 650px;
                    padding: 40px 0;
                    border: 1px solid #777;
                    margin-bottom: 30px;
                    padding-top: 50px;
                }


                .MainPage.preview {
                    transform: scale(0.4) translate(-75%, -75%);
                    height: 479px;
                    position: absolute;

                    @media #{$xl} {
                        transform: scale(0.5) translate(-50%, -50%);
                    }

                    &.editing {

                        .Headline,
                        .MusicianName {
                            border: 2px solid #777;
                        }
                    }

                    .Headline:disabled,
                    .MusicianName:disabled {
                        border: none;
                        background: none;
                    }
                }

                .SavedConfirmation {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(105, 54, 255, .8);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 45px;
                    font-weight: bold;
                    color: #FFF;
                    visibility: hidden;
                    opacity: 0;
                    transition: 300ms all ease-in-out;

                    &.saved {
                        visibility: visible;
                        opacity: 1;
                    }

                    .SavedConfirmation__message {
                        transform: rotate(-15deg);
                    }
                }
            }
        }
    }
}

.ios-users-message {
    margin-top: 10px;

    .anticon {
        margin-right: 2px;
    }
}