@import '../../../utils/_variables';
.Cover {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .ant-upload.ant-upload-select{
    display: block;
  }
  .Cover__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disabled{
      cursor: not-allowed;
    }
    .Cover__profile-image{
      width: 100%;
      &:before{
        content: "";
        display: block;
        padding-top: 45%;
      }
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      &>.bg-profile-image{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
      }
      &>.Cover__upload-button{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.7);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFF;
      }
    }
    .Cover__content{
      &>h3 {
        margin: 0 10px 0;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $copyBase;
      }
      &>p {
        margin: 0 10px 0px 10px;
        opacity: 0.5;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $copyBase;
      }
    }
  }
}
