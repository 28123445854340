@import '../../utils/_variables';

.MainMenuDrawer {
  @media #{$md}{
    display: none;
  }
  .MyAccountMenu{
    margin: 0;
    display: block;
    border-radius: 0;
    height: 100vh;
    @media #{$md}{
      display: none;
    }
    .logo{
      display: none;
    }
    .navigation .navigation__list li a{
      padding: 0px 10px 0px 30px;
      margin: 15px 0 20px;
    }
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
    box-shadow: none;
  }
  .ant-drawer-content{
    background: none;
    // max-width: 300px;
    .ant-drawer-body{
      padding: 0;
    }
  }
}

.MyAccountMenu{
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 15px;
  background-color: #f3f6fc;
  margin: 30px 0;
  background-position: -30px 45vh;
  background-size: 80vh;
  background-repeat: no-repeat;
  display: none;
  @media #{$md}{
    height: 90vh;
    display: block;
  }

  @media #{lg}{
    background-position: -30px 55vh;
    background-size: 100vh;
  }
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    figure{
      img{

      }
    }
  }
  .navigation{
    .navigation__list{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        &.li-logout{
          margin-top: 50px;
          a{
            color: $primary-color;
            &:hover{
              color: $primary-color;
              &:before{
                background: $primary-color;
              }
            }
          }
        }
        a{
          display: flex;
          position: relative;
          justify-content: flex-start;
          align-items: center;
          font-size: 13px;
          margin: 15px 0 30px;
          padding: 0px 10px 0px 22%;
          color: rgba(123, 136, 168, 0.7);
          font-weight: 300;
          &:hover{
            font-weight: 500;
            color: rgba(123, 136, 168, 0.9);
            &:before{
              content: "";
              display: block;
              position: absolute;
              height: 100%;
              width: 3px;
              top: 0;
              left: 0;
              background: rgba(123, 136, 168, 0.7);
            }
          }

          .nav-item-icon{
            padding: 6px;
            margin-right: 5px;
            svg{
              width: 20px;
              height: 20px;
            }
          }
          .extra-info{
            margin-left: 10px;
            color: $primary-color;
            svg{
              width: 14px;
              height: 14px;
            }
          }
          &.active{
            font-weight: 500;
            &:before{
              content: "";
              display: block;
              position: absolute;
              height: 100%;
              width: 3px;
              top: 0;
              left: 0;
              background: $primary-color;
            }
            .nav-item-icon{
              background: rgba(105, 53, 255, 0.1);
              border-radius: 7px;
              color: $primary-color;
              margin-right: 10px;
            }
            .extra-info{
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
