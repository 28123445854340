@import '../../utils/_variables';
.Recover__title{
  font-weight: 600;
  font-size: 30px;
  margin: 20px 0;
  text-align: center;
  color: $copyDarker;
  @media #{$md}{
    font-size: 30px;
    margin: 20px 0;
  }
  @media #{$lg}{
    text-align: center;
    font-size: 35px;
    margin: 20px 0;
  }
}
