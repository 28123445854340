@import '../../utils/_variables';
.Main__Footer{
  margin-top: 30px;
  @media #{$md}{
    position: absolute;
    margin-top: 0;
  }
  bottom: 0;
  left: 0;
  width: 100%;
  .Main__Footer__Wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    @media #{$md}{
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-left: 0;
    }
    .copyright{
      @media #{$md}{
        margin-right: auto;
        margin-left: 35px;
      }
    }
    .navigation-bottom{
      ul{
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        @media #{$md}{
          flex-direction: row;
        }
        li{
          margin-bottom: 5px;
          @media #{$md}{
            margin-right: 35px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
