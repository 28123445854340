@import '../../../utils/_variables';

.Messages{
  .ant-collapse{
    background: rgba(0,0,0,0);
    &.ant-collapse-borderless{
      border-radius: 7px !important;
      background: rgba(0,0,0,0);
      & > .ant-collapse-item{
        border-radius: 7px !important;
        border-bottom: 0px solid rgba(0,0,0,0) !important;
      }
    }
    .ant-collapse-item{
      background: #f8faff;
      border-radius: 7px;
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      &:before{
        content: '';
        position: absolute;
        width: 5px;
        height: 500px;
        left: 0;
        top: 0;
        background: #4ddffd;
      }
      .ant-collapse-header{
        padding: 20px 24px;
        font-size: 15px;
        font-weight: 500;
        color: $copyDarker;
      }
      &.ant-collapse-item-active{
        .ant-collapse-header{
          font-weight: 500;
          color: $primary-color;
        }
      }
      .ant-collapse-content{
        background: #f8faff;
        border-radius: 7px !important;
        padding: 0px 10px;
        &.ant-collapse-content-active{
        }
      }
      .ChevronIcon{
        right: 20px;
        &.active{
          // transform-origin: center;
          transform: rotate(180deg) !important;
          margin-top: -5px;
          margin-left: 5px;
        }
      }
    }
  }
}
