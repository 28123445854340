@import '../../../utils/_variables';

.title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  margin-bottom: 30px;
  max-width: 500px;
}
